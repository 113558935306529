<template>
  <van-list
    v-model="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
  >
    <van-grid :column-num="2" gutter="12" :border="false">
      <van-grid-item
        @click="goToUrl(shop.id)"
        v-for="(shop, s_index) in list"
        :key="s_index"
      >
        <template default>
          <div class="goods-layout">
            <div class="img-box">
              <img :src="shop.shop_logo" />
            </div>
            <div class="goods-detail">
              <div class="title-distance">
                <span class="title">{{ shop.shop_name }}</span>
<!--                <span class="distance">0.3 km</span>-->
              </div>
              <div class="grade">
                <div class="xingxing-box">
                  <img src="~@/assets/common/icon-good-opinion.png" />
                </div>
                <span>{{ shop.shop_score }}分</span>
              </div>
              <div class="location">
                <span class="address">{{ shop.address }}</span>
                <div class="address-img-box">
                  <img src="~@/assets/common/the_map_icon@2x.png" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </van-grid-item>
    </van-grid>
  </van-list>
</template>

<script>
import { getShopList } from "@/api/shop.js";

export default {
  name: "live-goods-list",
  props: ["shopClassId", "shopName", "byType"],
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
    };
  },
  watch:{
    "_props.shopClassId": function () {
      this.getShopList();
    },
    "_props.byType": function () {
      this.getShopList();
    },
    "_props.shopName": function () {
      this.getShopList();
    },
  },
  methods: {
    onLoad() {
      this.getShopList();
    },
    async getShopList() {
      const res = await getShopList({
        shop_name: this.shopName,
        class_id: this.shopClassId,
        by_type: 1,
      });
      if (res.code * 1 == 1) {
        this.list = res.data;
      }
      this.loading=false;
      this.finished=true;
    },
    goToUrl(shopId) {
      this.$router.push({ name: "shop-home", query: { shopId: shopId } });
    },
  },
};
</script>

<style lang="less" scoped>
// 公共样式
img {
  width: 100%;
  display: block;
}
// vant组件样式修改
/deep/.van-grid-item__content {
  padding: 0;
  border-radius: 10px;
  justify-content: start;
}
/deep/.van-grid-item {
  width: 188px;
}
.goods-layout {
  width: 188px;
  .img-box {
    width: 188px;
    height: 188px;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    img {
      width: 188px;
      height: 188px;
      display: block;
    }
  }
}
/deep/.van-grid {
  padding-left: 12px !important;
}
.van-list {
  margin-top: 12px;
}
// 商品详情
.goods-detail {
  color: #aaaaaa;
  font-size: 12px;
  padding: 10px;
  .title-distance {
    display: flex;
    align-items: end;
    .title {
      width: 110px;
      font-size: 16px;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .grade {
    display: flex;
    align-items: center;
    padding: 8px 0;
    .xingxing-box {
      width: 12px;
    }
    span {
      margin-left: 10px;
    }
  }
  .location {
    display: flex;
    align-items: center;
    .address-img-box {
      width: 20px;
      padding-left: 10px;
    }
    .address {
      font-size: 14px;
    }
  }
}
</style>
