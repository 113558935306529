<template>
  <div>
    <div>
      <Search
        class="search"
        :selectStr="goodsName"
        @setSelectStr="setSelectStr"
      >
        <div slot="left" class="search-left">
          <van-icon
            name="arrow-left"
            size="24"
            color="#6F6F6F"
            @click="$router.go(-1)"
          />
        </div>
        <div slot="right" class="search-right">
          <van-button
            type="danger"
            round
            block
            color="linear-gradient(to right, #ff6034, #ee0a24)"
            @click="onSearch"
          >
            搜索
          </van-button>
        </div>
      </Search>
      <div class="class-tab">
        <span @click="onClick(1)" :class="{ highlight: active === 1 }">
          商品
        </span>
        <span @click="onClick(2)" :class="{ highlight: active === 2 }">
          商家
        </span>
      </div>
    </div>
    <div class="content-layout">
      <GoodsShowLabel
        :sendType="sendType"
        v-if="show"
        :goodName="goodsName"
      ></GoodsShowLabel>
      <ShopList :shopName="goodsName" v-else></ShopList>
    </div>
  </div>
</template>

<script>
import Search from "@/components/search/search";
import GoodsShowLabel from "@/components/goods-show/goods-show-label";
import ShopList from "./shop-list.vue";
export default {
  name: "search-goods",
  components: {
    Search,
    GoodsShowLabel,
    ShopList,
  },
  data() {
    return {
      active: 1,
      show: true,
      goodsName: this.$route.query.searchStr,
      goodsName1: this.$route.query.searchStr,
      sendType: 2,
    };
  },
  mounted() {},
  methods: {
    onClick(num) {
      this.active = num;
      if (num === 2) {
        this.show = false;
      } else {
        this.show = true;
      }
    },
    setSelectStr(value) {
      this.goodsName1 = value;
    },
    onSearch() {
      this.goodsName = this.goodsName1;
    },
  },
};
</script>

<style lang="less" scoped>
.class-tab {
  position: fixed;
  top: 58px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  color: #6f6f6f;
  height: 48px;
  width: 100%;
  font-size: 18px;
  align-items: center;
  z-index: 200;
  span {
    display: block;
    padding: 0 16px;
  }
}
.highlight {
  font-weight: 500;
  color: #ed301d;
}
.search {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
}
.content-layout {
  padding-top: 122px;
}
</style>
